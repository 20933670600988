














import Vue from "vue"

import VideoLayout from "@/components/VideoPlayers/VideoLayout.vue"

export default Vue.extend({
  name: "YTPlayer",
  model: {
    prop: "value",
    event: "change"
  },
  components: { VideoLayout },
  props: {
    allowfullscreen: {
      type: Boolean,
      default: true
    },
    videoCode: {
      type: String,
      required: false
    },
    start: {
      type: Number,
      default: 0
    },
    controls: {
      type: [Number, Boolean],
      default: 1
    },
    loop: {
      type: [Number, Boolean],
      default: 0
    },
    frameborder: {
      type: Number,
      default: 0
    },
    volume: {
      type: Number,
      default: undefined
    },
    autoplay: {
      type: Boolean,
      default: undefined
    },
    sync: {
      type: Boolean,
      default: undefined
    },
    value: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      ignoreUpdateTimeout: null,
      ignoreUpdate: false,
      player: null
    }
  },
  computed: {
    id() {
      return `youtube-${this._uid}`
    },
    youtubeUrl() {
      return "https://www.youtube.com/embed/"
    },
    url() {
      return `${this.youtubeUrl}${this.videoCode}?start=${this.start}&mute=${
        this.muted
      }&controls=${+this.controls}&loop=${+this.loop}&enablejsapi=1&playlist=${
        this.videoCode
      }`
    },
    paused() {
      return this.value?.paused ?? false
    },
    startedAt() {
      return this.value?.startedAt ?? Date.now()
    },
    currentTime() {
      return this.value?.currentTime ?? 0
    }
  },
  mounted() {
    // @ts-expect-error: ignore
    this.player = new YT.Player(this.id, {
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    })
  },
  watch: {
    paused(value) {
      if (this.ignoreUpdate) return

      if (value) {
        this.pauseVideo()
      } else {
        this.playVideo()
      }
    }
  },
  methods: {
    onPlayerStateChange({ data }) {
      switch (data) {
        case 0:
          this.onEnd()
          break
        case 1:
          this.onPlay()
          break
        case 2:
          this.onPause()
          break
      }
    },
    onPlay() {
      this.$emit("play")
      this.onChange({ paused: false })
    },
    onPause() {
      this.$emit("pause")
      this.onChange({ paused: true })
    },
    onEnd() {
      this.$emit("end")
      this.onChange({ paused: true, currentTime: 0 })
    },
    onChange({ paused, currentTime = this.getCurrentTime() }) {
      if (!this.sync) return

      clearTimeout(this.ignoreUpdateTimeout)
      this.ignoreUpdate = true
      this.ignoreUpdateTimeout = setTimeout(() => {
        this.ignoreUpdate = false
      }, 500)

      this.$emit("change", {
        paused,
        currentTime,
        startedAt: Date.now()
      })
    },
    onPlayerReady() {
      this.$emit("ready")
      this.setVolume(this.volume)

      if (this.autoplay || (!this.autoplay && !this.paused)) {
        this.playVideo()
      }
    },

    getCurrentTime() {
      return this.player?.getCurrentTime() || 0
    },
    setVolume(volume) {
      if (volume && this.player) {
        this.player.setVolume(volume)
      }
    },
    setCurrentTime() {
      if (!this.player) return

      const offset = this.startedAt
        ? (Date.now() - this.startedAt) / 1000 || 0
        : 0
      const currentTime = this.currentTime + offset

      this.player.seekTo(currentTime + 0.06)
    },
    playVideo() {
      if (this.player) {
        this.player.playVideo()
        this.setCurrentTime()
      }
    },
    pauseVideo() {
      if (this.player) {
        this.player.pauseVideo()
      }
    }
  }
})
